* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.logo {
  height: 45px;
  max-height: 100%;
  width: 120px;
  background-image: url("./logo_white.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

// SCROLLBAR STYLE STARTS
/* width */
::-webkit-scrollbar {
  width: 5px;
  position: "absolute";
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, rgb(0, 234, 255), rgb(56, 0, 239));
  background-size: 100% 150%;
  background-position: 91% 100%;
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background-position: 10% 0%;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:active {
  cursor: grabbing;
}
// SCROLLBAR STYLE ENDS

html {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  background-color: #000000;
  position: relative;
  font-family: "Poppins", sans-serif;
}

body,
a {
  color: #eaeaea;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: color, font-size 300ms ease-out;

  &:hover {
    color: #bbb;
    // font-size: 1.2em;
  }
}

section {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 50px;
}

.heading {
  font-size: 4rem;
  line-height: 4rem;
  margin: 20px 0;
  font-weight: 500;
}

.subheading {
  font-size: 1.5rem;
  font-weight: 400;
  color: #d5d5d5;
  margin-left: 2px;
}

.btn {
  background-color: #ffffff;
  color: #000000;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;

  &.btn--icon {
    justify-content: start;
  }

  &-text {
    color: #000000;
    font-weight: 600;
  }

  &-icon {
    --side: 35px;
    height: var(--side);
    width: var(--side);
    margin: 10px 15px;
    background-repeat: no-repeat;
  }
}

section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 4vh;
  padding-left: 10vw;
  padding-right: 10vw;
  height: 100%;
  gap: 100px;
}

.page-holder {
  padding: 5vw;
  padding-left: 10vw;
  padding-right: 10vw;
}

.paragraph-holder {
  padding-bottom: 10px;

  & > ul {
    margin: 20px;
  }
}

.paragraph-section-label {
  padding-right: 4px;
}

@media (max-width: 1200px) {
  section {
    flex-direction: column-reverse;
    justify-content: space-around;
    text-align: center;
    padding: 2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    gap: 0px;
  }
  .heading {
    font-size: 3.2rem;
    line-height: 3.5rem;
  }
  .subheading {
    font-size: 1.5rem;
  }
}

// test code

.sec--reverse {
  flex-direction: row-reverse;
  text-align: right;
}

@keyframes rotate {
  from {
    rotate: 0deg;
  }

  50% {
    scale: 1 1.1;
  }

  to {
    rotate: 360deg;
  }
}

#blob {
  background-color: white;
  width: 50vh;
  max-width: 55vw;
  aspect-ratio: 1;
  position: absolute;
  left: 30%;
  top: 50%;
  border-radius: 50%;
  background: linear-gradient(to right, rgb(0, 234, 255), rgb(56, 0, 239));
  animation: rotate 20s infinite;
  z-index: -2;
  filter: blur(250px);
}
